<template>
  <base-section
    id="philosophie"
    class="grey lighten-4"
    space="36"
  >
    <v-container style="margin-bottom: -50px;">
      <base-section-heading title="Ma philosophie est simple">
        <p class="philo-text">Vous offrir des services comptables et fiscaux de qualité ainsi qu’une expérience personnalisée répondant à tous vos besoins.</p>
      </base-section-heading>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'Philosophie',
  }
</script>

<style scoped>
.philo-text {
  font-size: 27px;
  line-height: 40px;
}
</style>
